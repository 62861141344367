import { ProtectedRoute } from '../components/protected-route';
import { CONSTANTS } from '../utils/constants';
import { ErrorRoute } from './error';
import { HomeRoute } from './home';
import { LoginRoute } from './authentication';
import { SubmitCRMRoute } from './submit-crm';
import { SubmitEmailRoute } from './submit-email';
import {
    route,
    number,
    boolean,
    hashValues,
    string,
    union,
} from 'react-router-typesafe-routes/dom'; // Or /native

export const routeParamsRecord = {
    target: `targetId`,
    error: `errorType`,
    processId: `processId`,
    flow: `loginFlow`,
    code: `code`,
} as const;

export const ROUTES = {
    HOME: route(``),
    HOME_WITH_TARGET: route(`:${routeParamsRecord.target}`, {
        params: { targetId: string().defined() },
    }),
    PRE_LOGIN: route(`pre-authentication`),
    LOGIN: route(`authentication`),
    FINANCE_DATA_INIT: route(`finance/data/init`),
    FINANCE_DATA_UPLOAD: route(
        `finance/data/upload/process/:${routeParamsRecord.processId}`,
        {
            params: {
                [routeParamsRecord.processId]: string().defined(),
            },
        },
    ),
    FINANCE_DATA_SUBMIT: route(
        `finance/data/submit/process/:${routeParamsRecord.processId}`,
        {
            params: {
                [routeParamsRecord.processId]: string().defined(),
            },
        },
    ),
    VERIFICATION: route(`verification`),
    ERROR: route(`target/:${routeParamsRecord.target}/error`, {
        params: { targetId: string().defined() },
    }),
    ERROR_WITH_TYPE: route(
        `target/:${routeParamsRecord.target}/error/:${routeParamsRecord.error}`,
        {
            params: {
                [routeParamsRecord.target]: string().defined(),
                [routeParamsRecord.error]: string().defined(),
            },
        },
    ),
};

export const routesMap = [
    { path: `/`, element: <HomeRoute /> },
    { path: `/:${routeParamsRecord.target}`, element: <HomeRoute /> },
    {
        path: `/target/:${routeParamsRecord.target}/page/login`,
        element: <LoginRoute />,
    },
    {
        path: `/target/:${routeParamsRecord.target}/page/submit-email/process/:${routeParamsRecord.processId}`,
        element: (
            <ProtectedRoute>
                <SubmitEmailRoute />
            </ProtectedRoute>
        ),
    },
    {
        path: `/target/:${routeParamsRecord.target}/page/submit-crm/process/:${routeParamsRecord.processId}`,
        element: (
            <ProtectedRoute>
                <SubmitCRMRoute />
            </ProtectedRoute>
        ),
    },
    {
        path: `/target/:${routeParamsRecord.target}/error`,
        element: <ErrorRoute />,
    },
    {
        path: `/target/:${routeParamsRecord.target}/error/:${routeParamsRecord.error}`,
        element: <ErrorRoute />,
    },
] as const;
