import { useState } from 'react';

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export type DocumentType =
    | 'skattemelding_2022'
    | 'a-melding'
    | 'personal_info'
    | 'folkeregisteret';

export type DocumentLabel =
    | 'Skattemelding'
    | 'Amelding'
    | 'Personal Info'
    | 'Folkeregisteret';

export const documentTypeMap: Array<{
    name: DocumentType;
    label: DocumentLabel;
}> = [
    { name: 'skattemelding_2022', label: 'Skattemelding' },
    { name: 'a-melding', label: 'Amelding' },
    { name: 'personal_info', label: 'Personal Info' },
    { name: 'folkeregisteret', label: 'Folkeregisteret' },
];

export const useDocumentTypeSelect = () => {
    const [documentType, setDocumentType] =
        useState<DocumentType>('skattemelding_2022');

    const handleSelectDocumentType = (event: SelectChangeEvent) => {
        setDocumentType(event.target.value as DocumentType);
    };

    const DocumentTypeSelect = ({ disabled }: { disabled?: boolean }) => (
        <FormControl style={{ display: 'flex', width: '100%' }}>
            <Select
                variant="outlined"
                value={documentType}
                sx={{ minWidth: '200px' }}
                fullWidth={true}
                size="small"
                disabled={disabled}
                onChange={handleSelectDocumentType}
                data-size-small
            >
                {documentTypeMap.map((item) => (
                    <MenuItem data-no-icon value={item.name} key={item.label}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    return { DocumentTypeSelect, documentType };
};
