import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useProcessIdParam } from '../../hooks/use-process-id-param';
import { useTargetIdParam } from '../../hooks/use-target-id-param';
import { ROUTES } from '../../routes/routes-map';
import { useStore } from '../../store/store';

interface Props {
    children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: Props) => {
    const userData = useStore((state) => state.userData);
    const { targetId } = useTargetIdParam();
    const { processId } = useProcessIdParam();
    const navigate = useNavigate();

    /**
     * Process step is kept in the zustand store. Every process page has its
     * own step. This effect checks is
     */
    useEffect(() => {
        if (!userData.personNumber) {
            console.error(
                'Could not access protected route',
                'userId is missing',
            );
        }

        if (!processId) {
            console.error(
                'Could not access protected route',
                'processId is missing',
            );
        }

        if (!userData.personNumber || !processId) {
            navigate(ROUTES.HOME_WITH_TARGET.buildPath({
                targetId
            }))
        }
    }, [userData.personNumber, processId, navigate, targetId]);

    if (!userData.personNumber) {
        return null;
    }

    return <>{children}</>;
};
