import { devMode } from '../services/dev-mode';

interface UserEmailData {
    id: string;
    email: string;
}

/**
 * This user is used if the app is being used without a user id.
 *
 */
export const defaultUserEmail: UserEmailData = {
    id: 'default',
    email:
        devMode === 'dev'
            ? 'eiendoms@koiasoft.no'
            : 'kontakt@eiendomsfinans.no',
};

/**
 * Advisors data should not be used on dev environment.
 */
export const advisorsEmailData: UserEmailData[] = [
    { id: 'adam', email: 'adam@eiendomsfinans.no' },
    { id: 'fagerli', email: 'fagerli@eiendomsfinans.no' },
    { id: 'maristuen', email: 'maristuen@eiendomsfinans.no' },
    { id: 'aneta', email: 'aneta@eiendomsfinans.no' },
    { id: 'anton', email: 'anton@eiendomsfinans.no' },
    { id: 'atle', email: 'atle@eiendomsfinans.no' },
    { id: 'bjorn', email: 'bjorn@eiendomsfinans.no' },
    { id: 'borge', email: 'borge@eiendomsfinans.no' },
    { id: 'camilla', email: 'camilla@eiendomsfinans.no' },
    { id: 'celine', email: 'celine@eiendomsfinans.no' },
    { id: 'christer', email: 'christer@eiendomsfinans.no' },
    { id: 'christian', email: 'christian@eiendomsfinans.no' },
    { id: 'farestveit', email: 'farestveit@eiendomsfinans.no' },
    { id: 'daniel', email: 'daniel@eiendomsfinans.no' },
    { id: 'einar.ihlebak', email: 'einar.ihlebak@eiendomsfinans.no' },
    { id: 'elisabeth', email: 'elisabeth@eiendomsfinans.no' },
    { id: 'erlend', email: 'erlend@eiendomsfinans.no' },
    { id: 'even', email: 'even@eiendomsfinans.no' },
    { id: 'audunhus', email: 'audunhus@eiendomsfinans.no' },
    { id: 'finn', email: 'finn@eiendomsfinans.no' },
    { id: 'frederik', email: 'frederik@eiendomsfinans.no' },
    { id: 'frode', email: 'frode@eiendomsfinans.no' },
    {
        id: 'glenn.sigurd.syvaldsen',
        email: 'glenn.sigurd.syvaldsen@eiendomsfinans.no',
    },
    { id: 'hakan', email: 'hakan@eiendomsfinans.no' },
    { id: 'heidi', email: 'heidi@eiendomsfinans.no' },
    { id: 'jo', email: 'jo@eiendomsfinans.no' },
    { id: 'joackim', email: 'joackim@eiendomsfinans.no' },
    { id: 'jonas', email: 'jonas@eiendomsfinans.no' },
    { id: 'julianne', email: 'julianne@eiendomsfinans.no' },
    { id: 'katrine', email: 'katrine@eiendomsfinans.no' },
    { id: 'kjetil.rognerud', email: 'kjetil.rognerud@eiendomsfinans.no' },
    { id: 'knut', email: 'knut@eiendomsfinans.no' },
    { id: 'kristoffer', email: 'kristoffer@eiendomsfinans.no' },
    { id: 'borkowski', email: 'borkowski@eiendomsfinans.no' },
    { id: 'lasse', email: 'lasse@eiendomsfinans.no' },
    { id: 'solli', email: 'solli@eiendomsfinans.no' },
    { id: 'mads', email: 'mads@eiendomsfinans.no' },
    { id: 'monica', email: 'monica@eiendomsfinans.no' },
    { id: 'morten.brodersen', email: 'morten.brodersen@eiendomsfinans.no' },
    { id: 'strande', email: 'strande@eiendomsfinans.no' },
    { id: 'bakken', email: 'bakken@eiendomsfinans.no' },
    { id: 'per.magne', email: 'per.magne@eiendomsfinans.no' },
    { id: 'roar', email: 'roar@eiendomsfinans.no' },
    { id: 'olsen', email: 'olsen@eiendomsfinans.no' },
    { id: 'salimi', email: 'salimi@eiendomsfinans.no' },
    { id: 'sebastian', email: 'sebastian@eiendomsfinans.no' },
    { id: 'abid', email: 'abid@eiendomsfinans.no' },
    { id: 'simen', email: 'simen@eiendomsfinans.no' },
    { id: 'stephan', email: 'stephan@eiendomsfinans.no' },
    { id: 'hval', email: 'hval@eiendomsfinans.no' },
    { id: 'dullum', email: 'dullum@eiendomsfinans.no' },
    { id: 'tb', email: 'tb@eiendomsfinans.no' },
    { id: 'tk', email: 'tk@eiendomsfinans.no' },
    { id: 'tove', email: 'tove@eiendomsfinans.no' },
    { id: 'zulnare', email: 'zulnare@eiendomsfinans.no' },
    { id: 'mattias', email: 'mattias@eiendomsfinans.no' },
    { id: 'sj', email: 'sj@eiendomsfinans.no' },
    { id: 'ruben', email: 'ruben@eiendomsfinans.no' },
    { id: 'ausland', email: 'ausland@eiendomsfinans.no' },
    { id: 'henrik', email: 'henrik@eiendomsfinans.no' },
    { id: 'vilde', email: 'vilde@eiendomsfinans.no' },
    { id: 'aleksander', email: 'aleksander@eiendomsfinans.no ' },
    { id: 'andreas', email: 'andreas@eiendomsfinans.no' },
    { id: 'rezhan', email: 'rezhan@eiendomsfinans.no' },
    { id: 'berk', email: 'berk@eiendomsfinans.no' },
];

/**
 * Only those users will be `available` to use in dev environment
 * These users will be also available in production environment
 */
export const internalEmailData: UserEmailData[] = [
    defaultUserEmail,
    { id: 'ole', email: 'ole@eiendomsfinans.no' },
    { id: 'k.kupiec', email: 'k.kupiec@koiasoft.no' },
    { id: 'b.skorka', email: 'b.skorka@koiasoft.no' },
    { id: 'rune', email: 'rune@upscore.no' },
    { id: 'herman', email: 'herman@brunborg.com' },
    { id: 'junmiao', email: 'junmiao@koiasoft.no' },
    { id: 'stine', email: 'stinebroso@gmail.com' },
];
