import create from 'zustand';
import { persist } from 'zustand/middleware';

import {
  LoginFlow,
  SendingFlow,
} from '../utils/types';

interface Store {
    userData: {
        personNumber: string;
        phoneNumber?: string;
    };

    setUserData: (payload: {
        personNumber: string;
        phoneNumber?: string;
    }) => void;

    destinationKey: string;

    setDestinationKey: (payload: string) => void;

    loginFlow: LoginFlow;

    setLoginFlow: (payload: LoginFlow) => void;

    sendingFlow: SendingFlow;

    setSendingFlow: (payload: SendingFlow) => void;

    gjeldsConsent: boolean;

    setGjeldsConsent: (payload: boolean) => void;

    loginSessionId: string;

    setLoginSessionId: (payload: string) => void;

    setProcessExists: (payload: {
        processId: string;
        personNumber: string;
        gjeldsConsent: boolean;
        loginFlow: LoginFlow;
        sendingFlow: SendingFlow;
        destinationKey: string;
    }) => void;

    reset: () => void;
}

const initialState: Pick<
    Store,
    | 'userData'
    | 'loginFlow'
    | 'sendingFlow'
    | 'destinationKey'
    | 'gjeldsConsent'
    | 'loginSessionId'
> = {
    userData: {
        personNumber: '',
        phoneNumber: '',
    },
    loginFlow: 'BANKID_MOBILE',
    sendingFlow: 'EMAIL',
    destinationKey: '',
    gjeldsConsent: false,
    loginSessionId: '',
};

/**
 * Application store build with Zustand and persist with sessionStorage.
 * Persisting with sessionStorage is needed to keep the state after user is
 * being redirected to the login page (which happens on different domain).
 */
export const useStore = create<Store>()(
    persist(
        (set, get) => ({
            ...initialState,

            setUserData(payload) {
                set((state) => ({ ...state, userData: payload }));
            },

            setLoginFlow(payload) {
                set((state) => ({ ...state, loginFlow: payload }));
            },

            setSendingFlow(payload) {
                set((state) => ({ ...state, sendingFlow: payload }));
            },

            setDestinationKey(payload) {
                set((state) => ({ ...state, destinationKey: payload }));
            },

            setGjeldsConsent(payload) {
                set((state) => ({ ...state, gjeldsConsent: payload }));
            },

            setLoginSessionId(payload) {
                set((state) => ({ ...state, loginSessionId: payload }));
            },

            setProcessExists(payload) {
                set((state) => ({
                    ...state,
                    userData: {
                        personNumber: payload.personNumber,
                    },
                    gjeldsConsent: payload.gjeldsConsent,
                    loginFlow: payload.loginFlow,
                    sendingFlow: payload.sendingFlow,
                    destinationKey: payload.destinationKey,
                }));
            },

            reset() {
                sessionStorage.removeItem('app-store');
                set(initialState);
            },
        }),
        {
            name: 'app-store',
            getStorage: () => sessionStorage,
        },
    ),
);
